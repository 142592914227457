<template>
  <a :href="landingUrl" class="group relative">
    <img class="relative z-10 w-32" src="/images/logo/logo-prelist.svg" alt="Prelist logo" />
  </a>
</template>

<script>
export default {
  computed: {
    landingUrl() {
      const hostname = window.location.hostname
      if (hostname === 'app.prelist.io') {
        return 'https://prelist.io'
      } else if (hostname === 'app.stage.prelist.io') {
        return 'https://landing.stage.prelist.io'
      } else {
        return '/' // Fallback, e.g., to the root of the current site
      }
    },
  },
}
</script>
